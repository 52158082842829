import {Injectable, NgZone} from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class ScrollService {
  constructor(private ngZone: NgZone) {}

  scrollToFragment(fragment: string, yOffset: number = 0): void {
    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        const element = document.getElementById(fragment);
        if (element) {
          const yPosition =
            element.getBoundingClientRect().top + window.scrollY + yOffset;

          window.scrollTo({ top: yPosition, behavior: 'smooth' });
        } else {
          console.warn(`Element with ID "${fragment}" not found.`);
        }
      }, 100);
    });
  }
}
