<div class="section-panel center gap-5">
  <div class="section-panel-image"><img src="assets/images/overview-analysis.png"/> </div>
  <div class="section-panel-content column">
    <div class="section-panel-content-title">   Analyze CVs And Extract Key Insights    </div>
    <div class="section-panel-content-text">
      HireFox analyzes your CVs, extracts data and enhances them with valuable insights in the form of red/green flags.
    </div>
    <section-panel-button [text]="BUTTON_TEXT" [url]="BUTTON_URL" [bgColor]="BUTTON_BG_COLOR"/>
  </div>
</div>
