<div id="job-matching" class="section column center gap-5">
  <div class="section-panel">
    <div
      id="job-matching-content"
      class="section-left column column-center-left gap-1"
    >
      <div class="title">Job Matching Assistant</div>
      <div class="subtitle">
        The Job Matching assistant uses your own scoring instruction and rejection criteria to match candidates to jobs. The assistant evaluates candidates using your own scoring rules and creates a thorough evaluation report for each candidate.
      </div>
      <div class="list column">
        <list-item text="Match Candidates To Jobs Using Your Own Scoring Rules"/>
        <list-item text="Auto-Reject Candidates Based On Your Own Rejection Criteria"/>
        <list-item text="AI Generated, Candidate Evaluation Reports"/>
      </div>
      <section-panel-button [text]="BUTTON_TEXT" [url]="BUTTON_URL" [fragment]="BUTTON_URL_FRAGMENT" [bgColor]="BUTTON_BG_COLOR"/>
    </div>
    <div class="section-right center-vertical">
      <div id="job-matching-image" class="section-image">
        <img src="assets/images/ai-assistant-job-matching.png" />
      </div>
    </div>
  </div>
</div>
