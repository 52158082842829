<div class="section-panel center gap-5">
  <div class="section-panel-content column">
    <div class="section-panel-content-title"> Searchable CV Repository    </div>
    <div class="section-panel-content-text">
      Create a database that stores all your CVs and allows you to search for any CV using advanced filtering capabilities.
    </div>
    <section-panel-button [text]="BUTTON_TEXT" [url]="BUTTON_URL" [bgColor]="BUTTON_BG_COLOR"/>
  </div>
  <div class="section-panel-image"><img src="assets/images/overview-repository.png"/> </div>
</div>
