<div id="pricing" class="section column content-section">
  <div class="column gap-3">
    <div class="column center">
      <div id="pricing-title">Flexible Plans That Fit Every Need</div>
      <div id="pricing-subtitle">Start small and expand as you go</div>
    </div>

    <div class="column center gap-2">
      <div class="free-signup center">
        <div class="column free-signup-plan">
          <div>Free</div>
          <div>
            Sign up and get &nbsp;<strong>10 credits</strong>&nbsp; for free!
          </div>
        </div>
        <div class="free-signup-price">
          <span>0</span>
        </div>
        <a href="https://app.hirefox.ai" class="button">Get Started</a>
      </div>
      <div class="gap-3 center wrap pricing-cards-container">
        <pricing-card
            *ngFor="let p of pricingPlans"
            [plan]="p"
        ></pricing-card>
      </div>
    </div>
  </div>
</div>
