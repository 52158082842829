import { Component, Input, OnInit } from '@angular/core';
import {EPricingPlanType, IPricingPlan} from "../../../model/IPricingPlan";
import {UrlService} from "../../../Services/url.service";

@Component({
  selector: 'pricing-card',
  templateUrl: './pricing-card.component.html',
  styleUrls: ['./pricing-card.component.css'],
})
export class PricingCardComponent implements OnInit {

  @Input() plan: IPricingPlan | undefined;
  selectedNumber: number = 0;

  constructor(private urlService: UrlService) {}

  ngOnInit(): void {
    this.selectedNumber = this.plan?.creditOptions ? this.plan.creditOptions[0] : 0;
  }

  selectPrice(number: number) {
    this.selectedNumber = number;
  }

  getPricingLink() {
    const baseUrl: string = this.urlService.app;
    const params: string = `?subscription_type=${this.plan?.type}&subscription_credits=${this.selectedNumber}`;
    return (this.plan?.type === EPricingPlanType.CUSTOM) ? `mailTo:${this.urlService.email}` : baseUrl+params;
  }

  protected readonly EPricingPlanType = EPricingPlanType;
}
