<div id="content-extraction" class="section column center gap-5">
  <div class="section-panel">
    <div
      id="content-extraction-content"
      class="section-left column column-center-left gap-1"
    >
      <div class="title">CV Information Extraction Assistant</div>
      <div class="subtitle">
        The content extraction assistant extracts every bit of information from your CVs in an accurate and reliable way.
      </div>
      <div class="list column">
          <list-item text="Process Different Types Of Files (PDF, Word, Image)"></list-item>
          <list-item text="Accurately Extract All Information From CVs"></list-item>
          <list-item text="Generate A ‘Quick Overview’ For Each Candidate Including All The Important Details You Want To See In A Glance"></list-item>
          <list-item text="Extract Key Information Such As Candidate Seniority, Job Tenure And Employment Gaps"></list-item>
      </div>
      <section-panel-button [text]="BUTTON_TEXT" [url]="BUTTON_URL" [fragment]="BUTTON_URL_FRAGMENT" [bgColor]="BUTTON_BG_COLOR"/>
    </div>
    <div class="section-right center-vertical">
      <div id="content-extraction-image" class="section-image">
        <img src="assets/images/ai-assistant-content-extraction.png" />
      </div>
    </div>
  </div>
</div>
