import { Component } from '@angular/core';
import {EButtonTheme} from "../../../../common/section-panel-button/section-panel-button.component";

@Component({
  selector: 'overview-matching',
  templateUrl: './overview-matching.component.html',
  styleUrls: ['./overview-matching.component.css']
})
export class OverviewMatchingComponent {
  readonly BUTTON_TEXT: string = "Find out more...";
  readonly BUTTON_URL: string = "/product";
  readonly BUTTON_BG_COLOR: EButtonTheme = EButtonTheme.orange;
}
