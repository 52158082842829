<div class="section-panel center gap-5">
  <div class="section-panel-image"><img src="assets/images/overview-reports.png"/> </div>
  <div class="section-panel-content column">
    <div class="section-panel-content-title">   Candidate Evaluation Reports    </div>
    <div class="section-panel-content-text">
      Thorough, AI Generated reports for every candidate.
    </div>
    <section-panel-button [text]="BUTTON_TEXT" [url]="BUTTON_URL" [bgColor]="BUTTON_BG_COLOR"/>
  </div>
</div>
