import { Component } from '@angular/core';
import {EButtonTheme} from "../../../common/section-panel-button/section-panel-button.component";
import {EProductElementIds} from "../../product/product.component";

@Component({
  selector: 'cv-analysis-assistant',
  templateUrl: './cv-analysis-assistant.component.html',
  styleUrls: ['./cv-analysis-assistant.component.css']
})
export class CvAnalysisAssistantComponent {
  readonly BUTTON_TEXT: string = "Read More...";
  readonly BUTTON_URL: string = "/product";
  readonly BUTTON_URL_FRAGMENT: EProductElementIds = EProductElementIds.resumeAnalysis;
  readonly BUTTON_BG_COLOR: EButtonTheme = EButtonTheme.grayGreenDark;
}
