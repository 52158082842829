<div id="ai-assistants-personalized" class="section column center gap-5">
  <div class="section-panel">
    <div class="section-left center-vertical">
      <div id="ai-assistants-personalized-image" class="section-image">
        <img src="assets/images/ai-assistant-personalized.png" />
      </div>
    </div>
    <div
      id="ai-assistants-personalized-content"
      class="section-right column column-center-left gap-1"
    >
      <div class="title">Personalized AI Assistants</div>
      <div class="subtitle">
        Your Powerful Recruitment Allies.
      </div>
      <div class="ai-assistants-personalized-text">
        AI Assistants are built to help with different hiring tasks. They use information from CVs and your specific rules and guidelines to extract information, analyze it, and make decisions. For example, an assistant can pull out key information and insights from hundreds of CVs in just a few minutes, saving a lot of time compared to doing it manually.
      </div>
      <section-panel-button [text]="BUTTON_TEXT" [url]="BUTTON_URL" [target]="BUTTON_TARGET" [bgColor]="BUTTON_BG_COLOR"/>
    </div>
  </div>
</div>
