<div class="section-panel center gap-5">
  <div class="section-panel-image"><img src="assets/images/robot.png"/> </div>
  <div class="section-panel-content column">
    <div class="section-panel-content-title">   Personalized  Hiring Assistants    </div>
    <div class="section-panel-content-text">
      Create personalized AI hiring assistants using your own evaluation criteria,
      enabling them to replicate your hiring practices and decision-making style.
    </div>
    <section-panel-button [text]="BUTTON_TEXT" [url]="BUTTON_URL" [bgColor]="BUTTON_BG_COLOR"/>
  </div>
</div>
