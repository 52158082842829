<div id="cv-analysis" class="section column center gap-5">
  <div class="section-panel">
    <div class="section-left center-vertical">
      <div id="cv-analysis-image" class="section-image">
        <img src="assets/images/ai-assistant-analysis.png" />
      </div>
    </div>
    <div
      id="cv-analysis-content"
      class="section-right column column-center-left gap-1"
    >
      <div class="title">CV Analysis Assistant</div>
      <div class="subtitle">
        The CV Analysis Assistant uses your instructions to generate insights about candidates based on the information in their CVs.
      </div>
      <div class="list column">
        <list-item text="Extract Personalized Insights Based On Your Input"></list-item>
        <list-item text="Create Meaningful Indicators To Quickly Identify Candidate Strength And Weaknesses"></list-item>
        <list-item text="Extract Custom Insights"></list-item>
      </div>
      <section-panel-button [text]="BUTTON_TEXT" [url]="BUTTON_URL" [fragment]="BUTTON_URL_FRAGMENT" [bgColor]="BUTTON_BG_COLOR"/>
    </div>
  </div>
</div>
