import {Component} from '@angular/core';
import {EPricingPlanType, IPricingPlan} from "../../model/IPricingPlan";


@Component({
  selector: 'pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.css']
})
export class PricingComponent {

  pricingPlans: IPricingPlan[] = [
    {
      type: EPricingPlanType.START,
      description: 'Start small and grow as you need.',
      creditOptions: [200, 500, 1000, 1500],
      pricePerCredit: 0.25
    },
    {
      type: EPricingPlanType.SCALE,
      description: 'Boost your capacity with scalable credit volumes',
      creditOptions: [2000, 5000, 10000, 15000, 20000],
      pricePerCredit: 0.2
    },
    {
      type: EPricingPlanType.CUSTOM,
      description: 'Need a custom plan ? Contact us to give you a special quote tailored to your needs.',
      creditOptions: null,
      pricePerCredit: null
    }
  ]

}
