export enum EPricingPlanType {
    FREE = "free",
    START = "start",
    SCALE = "scale",
    CUSTOM = "custom"
}

export interface IPricingPlan {
    type: EPricingPlanType,
    description: string,
    creditOptions: number[] | null,
    pricePerCredit: number | null,
}