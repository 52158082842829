<div class="section-panel center gap-5">
  <div class="section-panel-content column">
    <div class="section-panel-content-title"> Hiring Pipeline Management   </div>
    <div class="section-panel-content-text">
      Manage your hiring pipeline and effortlessly track and enhance candidate progress.
    </div>
    <section-panel-button [text]="BUTTON_TEXT" [url]="BUTTON_URL" [bgColor]="BUTTON_BG_COLOR"/>
  </div>
  <div class="section-panel-image"><img src="assets/images/overview-pipeline.png"/> </div>
</div>
