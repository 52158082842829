import { Component } from '@angular/core';
import {EButtonTarget, EButtonTheme} from "../../../common/section-panel-button/section-panel-button.component";
import {MatDialog} from "@angular/material/dialog";
import {UrlService} from "../../../Services/url.service";
import {Router} from "@angular/router";
import {GoogleAnalyticsService} from "../../../Services/google-analytics.service";

@Component({
  selector: 'personalized-ai-assistants',
  templateUrl: './personalized-ai-assistants.component.html',
  styleUrls: ['./personalized-ai-assistants.component.css']
})
export class PersonalizedAiAssistantsComponent {

  constructor(
    public urlService: UrlService,
  ) {}

  readonly BUTTON_TEXT: string = "Try Hirefox For Free!";
  readonly BUTTON_URL: string = this.urlService.register;
  readonly BUTTON_TARGET: EButtonTarget = EButtonTarget.blank;
  readonly BUTTON_BG_COLOR: EButtonTheme = EButtonTheme.orange;
}
