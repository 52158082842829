import {Component, Input} from '@angular/core';

export enum EButtonTheme {
  orange = 'orange',
  grayGreenLight = 'gray-green-light',
  grayGreenDark = 'gray-green-dark',

}

export enum EButtonTarget {
  self = "_self",
  blank = "_blank",
  parent = "_parent",
  top = "_top"

}

@Component({
  selector: 'section-panel-button',
  templateUrl: './section-panel-button.component.html',
  styleUrls: ['./section-panel-button.component.css']
})

export class SectionPanelButtonComponent {

  @Input() text: string = "";
  @Input() url: string = "";
  @Input() fragment: string |undefined;
  @Input() target: EButtonTarget = EButtonTarget.self;
  @Input() bgColor: EButtonTheme = EButtonTheme.orange;

}
